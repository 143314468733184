import { useMutation } from '@apollo/client';
import { MenuItem, Stack } from '@mui/material';
import { ProductInventoryType } from 'components/inventory/data';
import { Typography } from 'components/shared';
import { TOGGLE_INVENTORY_LOCK } from 'graphql/mutations/inventory.mutation';
import React from 'react';
import { sendCatchFeedback, sendFeedback } from 'utils/feedback';

type Props = {
  handleClose: () => void;
  product: ProductInventoryType;
  refetch: () => void;
};

export const LockInventoryToggle: React.FC<Props> = ({
  handleClose,
  product,
  refetch,
}) => {
  const [toggleLocked] = useMutation(TOGGLE_INVENTORY_LOCK);

  const onSubmit = async () => {
    try {
      const response = await toggleLocked({
        variables: {
          data: {
            product_id: product.id,
            status: product.locked_status === 'locked' ? 'unlocked' : 'locked',
          },
        },
      });

      if (response.data?.toggleLocked?.status) {
        handleClose();
        refetch();

        return sendFeedback(response.data?.toggleLocked?.message, 'success');
      } else {
        return sendFeedback(response.data?.toggleLocked?.message, 'error');
      }
    } catch (error) {
      sendCatchFeedback(error);
    }
  };
  return (
    <MenuItem
      onClick={() => {
        onSubmit();
      }}
    >
      <Stack spacing={4} alignItems='center' direction='row'>
        <Typography>
          {product.locked_status === 'locked' ? 'Activate Product' : 'Deactivate Product'}
        </Typography>
      </Stack>
    </MenuItem>
  );
};
