import {
  Box,
  CircularProgress,
  FormLabel,
  Input,
  InputAdornment,
  InputProps,
  Typography,
} from '@mui/material';
import React from 'react';
import { FieldError } from 'react-hook-form';

import { PasswordPolicy } from 'components/password-policy';
import { colors } from 'theme/color';

type InputType = Omit<InputProps, 'error'>;

type Props = InputType & {
  label?: string;
  required?: boolean;
  hasPolicy?: boolean;
  watchValue?: string;
  error?: FieldError;
  containerWidth?: string;
  disableMargin?: boolean;
  loading?: boolean;
};

export const FormInput = React.forwardRef(
  (
    {
      label,
      hasPolicy,
      watchValue,
      required = false,
      error,
      containerWidth = 'fit-contents',
      disableMargin = false,
      loading,
      ...resProps
    }: Props,
    ref
  ) => {
    return (
      <Box marginTop={!disableMargin ? 2 : 0} width={containerWidth}>
        {label && (
          <FormLabel sx={{ fontWeight: 600, color: '' }} htmlFor={label}>
            {label}{' '}
            {required ? (
              <Box component='span' color={colors.red['main']}>
                *
              </Box>
            ) : null}
          </FormLabel>
        )}
        <Input
          ref={ref}
          disableUnderline
          sx={{
            marginTop: !disableMargin ? 1 : 0,
            minHeight: 45,
            paddingX: 2,
            paddingY: 1,
            border: `1px solid ${
              error?.message ? colors.red['main'] : colors.secondary['mid']
            }`,
            borderRadius: '8px',
            backgroundColor: colors.white,
            '&.Mui-disabled': {
              backgroundColor: colors.black.inputDisabled,
              color: colors.black.light,
              borderColor: colors.black.borderDisabled,
            },
          }}
          id={label}
          fullWidth
          endAdornment={
            loading && (
              <InputAdornment position='end'>
                <CircularProgress size={20} sx={{ color: colors.secondary['light'] }} />
              </InputAdornment>
            )
          }
          {...resProps}
        />
        {error?.message && !hasPolicy && (
          <Box mt='4px'>
            {error?.message && (
              <Typography variant='md' color={colors.red['main']}>
                {error?.message}
              </Typography>
            )}
          </Box>
        )}

        {/* PASSWORD POLICY */}
        {hasPolicy && <PasswordPolicy value={watchValue!} error={error?.message} />}
      </Box>
    );
  }
);
