import { zodResolver } from '@hookform/resolvers/zod';
import { Box, Stack } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { Button, FormInput } from 'components/shared';
import { FormSelect } from 'components/shared/select';
import { RoutePath } from 'constants/routes-path';
import { EditInventoryFieldType, EditInventorySchema } from 'schema/inventory.schema';
import { colors } from 'theme/color';

import { useMutation, useQuery } from '@apollo/client';
import { UPDATE_INVENTORY } from 'graphql/mutations/inventory.mutation';
import { GET_CATALOGUE_ENTITY } from 'graphql/queries/inventory.queries';
import { sendCatchFeedback, sendFeedback } from 'utils/feedback';
import { ProductInventoryType } from '../data';

type Props = {
  product: ProductInventoryType;
};
export const EditInventoryForm: React.FC<Props> = ({ product }) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    control,
  } = useForm<EditInventoryFieldType>({
    resolver: zodResolver(EditInventorySchema),
    defaultValues: {
      category: product?.category || '',
    },
  });
  const navigate = useNavigate();
  const [updateInventory, { loading }] = useMutation(UPDATE_INVENTORY);
  const { data: categoryData, loading: categoryLoading } = useQuery(
    GET_CATALOGUE_ENTITY,
    {
      variables: {
        entityType: 'category',
      },
    }
  );

  const navigateToInventory = () => {
    navigate(RoutePath.INVENTORY);
  };

  const onSubmit = async (data: EditInventoryFieldType) => {
    try {
      await updateInventory({
        variables: {
          data: {
            id: product.id,
            name: data.name,
            description: data.description,
            category: data.category,
            // quantity_in_stock: Number(data.quantity_in_stock || 0),
            sale_price: Number(data.amount || 0),
            // date: new Date(data.date),
          },
        },
      });
      sendFeedback('Inventory Updated', 'success');
      navigate(RoutePath.INVENTORY, { replace: true });
    } catch (error) {
      sendCatchFeedback(error);
    }
  };

  // Reset form fields
  useEffect(() => {
    if (product) {
      setValue('name', product.name);
      setValue('description', product.description);
      setValue('category', product.category);
      // setValue('quantity_in_stock', product.quantity_in_stock?.toString());
      setValue('amount', product.amount?.toString());
      // setValue('date', new Date(product.created_at).toISOString() as unknown as string);
    }
  }, [product, setValue]);

  return (
    <Box
      padding={4}
      bgcolor={colors.white}
      borderRadius={'16px'}
      component='form'
      onSubmit={handleSubmit(onSubmit)}
    >
      <Grid2 container spacing={4}>
        <Grid2 xs={12}>
          <FormInput
            label='Product Name'
            error={errors?.name}
            required
            disabled
            placeholder='Enter product name'
            {...register('name')}
          />
          <FormInput
            label='Description'
            error={errors?.description}
            rows={4}
            multiline
            required
            placeholder='Enter product description'
            {...register('description')}
          />
          <Stack
            direction='row'
            spacing={3}
            useFlexGap
            mt={2}
            width={'100%'}
            flexWrap={{ xs: 'wrap', lg: 'nowrap' }}
          >
            <Controller
              control={control}
              {...register('category')}
              render={({ field }) => (
                <FormSelect
                  {...field}
                  label='Category'
                  error={errors?.category}
                  required
                  placeholder='Enter product category'
                  containerWidth='100%'
                  options={
                    categoryData?.getAdminCatalogueEntity?.data?.map((item: string) => ({
                      label: item,
                      value: item,
                    })) || []
                  }
                  onChange={(e) => {
                    setValue('category', e.target.value as string);
                  }}
                  disabled={categoryLoading}
                />
              )}
            />
          </Stack>
          {/* <Stack
            direction='row'
            spacing={3}
            useFlexGap
            mt={2}
            width={'100%'}
            flexWrap={{ xs: 'wrap', lg: 'nowrap' }}
          >
            <FormInput
              label='Stock Quantity'
              error={errors?.quantity_in_stock}
              required
              placeholder='Enter stock quantity'
              {...register('quantity_in_stock')}
              containerWidth='100%'
            />
          </Stack> */}
          <Stack
            direction='row'
            spacing={3}
            useFlexGap
            mt={2}
            width={'100%'}
            flexWrap={{ xs: 'wrap', lg: 'nowrap' }}
          >
            <FormInput
              label='Product Price'
              error={errors?.amount}
              disabled
              required
              placeholder='Enter product regular amount'
              {...register('amount')}
              // format value as amount
              value={product?.amount?.toLocaleString('en-NG', {
                style: 'currency',
                currency: 'NGN',
              })}
              containerWidth='100%'
            />
          </Stack>
          {/* <Stack
            direction='row'
            spacing={3}
            useFlexGap
            mt={2}
            width={'100%'}
            flexWrap={{ xs: 'wrap', lg: 'nowrap' }}
          >
            <FormInput
              label='Date Added'
              error={errors?.date}
              required
              type='datetime-local'
              placeholder='Enter product creation date'
              {...register('date')}
              containerWidth='100%'
            />
          </Stack> */}
        </Grid2>
      </Grid2>
      <Stack
        mt={10}
        justifyContent='flex-end'
        direction='row'
        spacing={3}
        useFlexGap
        flexWrap={{ xs: 'wrap', lg: 'nowrap' }}
      >
        <Button
          type='submit'
          style={{ width: '250px', maxWidth: '100%' }}
          loading={loading}
        >
          Update
        </Button>
        <Button
          style={{ width: '250px', maxWidth: '100%' }}
          onClick={navigateToInventory}
          variant='outlined'
        >
          Cancel
        </Button>
      </Stack>
    </Box>
  );
};
